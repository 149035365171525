import {
  CompanyProviderIncomePaymentMethod,
  CompanyProviderOutcomePaymentMethod,
  CounterpartyPaymentAccountTransferType,
} from '@/enums';

export const CompanyProviderIncomePaymentMethodsMap = {
  [CompanyProviderIncomePaymentMethod.InboundAch]: {
    title: 'ACH',
  },
  [CompanyProviderIncomePaymentMethod.InboundWire]: {
    title: 'Fedwire',
  },
  [CompanyProviderIncomePaymentMethod.InboundSwift]: {
    title: 'SWIFT',
  },
  [CompanyProviderIncomePaymentMethod.InboundCrypto]: {
    title: 'Crypto',
  },
  [CompanyProviderIncomePaymentMethod.InboundKarta]: {
    title: 'Karta',
  },
} as const;

export const CompanyProviderOutcomePaymentMethodsMap: Record<
  CompanyProviderOutcomePaymentMethod,
  {
    title: string;
    counterpartyPaymentAccountTransferType: CounterpartyPaymentAccountTransferType;
  }
> = {
  [CompanyProviderOutcomePaymentMethod.OutboundAch]: {
    title: 'ACH',
    counterpartyPaymentAccountTransferType:
      CounterpartyPaymentAccountTransferType.Ach,
  },
  [CompanyProviderOutcomePaymentMethod.OutboundWire]: {
    title: 'Fedwire',
    counterpartyPaymentAccountTransferType:
      CounterpartyPaymentAccountTransferType.Fedwire,
  },
  [CompanyProviderOutcomePaymentMethod.OutboundKarta]: {
    title: 'Karta',
    counterpartyPaymentAccountTransferType:
      CounterpartyPaymentAccountTransferType.Karta,
  },
  [CompanyProviderOutcomePaymentMethod.OutboundSwift]: {
    title: 'SWIFT',
    counterpartyPaymentAccountTransferType:
      CounterpartyPaymentAccountTransferType.Swift,
  },
} as const;

export const COMPANY_PROVIDER_INCOME_PAYMENT_METHODS_FOR_WIRE = [
  CompanyProviderIncomePaymentMethod.InboundAch,
  CompanyProviderIncomePaymentMethod.InboundSwift,
  CompanyProviderIncomePaymentMethod.InboundWire,
];

export const COMPANY_PROVIDER_INCOME_PAYMENT_METHODS_FOR_CRYPTO = [
  CompanyProviderIncomePaymentMethod.InboundCrypto,
];
